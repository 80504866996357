import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Box, Typography, TextField, Button, Paper, Grid, Select, MenuItem, InputLabel, FormControl, Stepper, Step, StepLabel, Alert } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledPaper = styled(Paper)(({ theme }) => ({
  marginTop: theme.spacing(8),
  marginBottom: theme.spacing(8),
  padding: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  background: 'linear-gradient(135deg, #ffffff 0%, #f0f4f8 100%)',
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(3),
  padding: '12px 24px',
  fontSize: '1.1rem',
}));

const steps = ['User Info', 'Veterinarian Info'];

function BrickAndMortarSignup() {
    const [activeStep, setActiveStep] = useState(0);
    const [formData, setFormData] = useState({
        username: '', password: '', first_name: '', last_name: '', degrees: '',
        city: '', state: '', zip_code: '', specialty: '', email: '', phone_number: '',
        practice_name: ''
    });
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [touched, setTouched] = useState({});
    const navigate = useNavigate();
    const apiUrl = process.env.REACT_APP_API_URL || '';

    const specialties = ['General Practice', 'Nutrition', 'Internal Medicine', 'Dermatology', 'Cardiology', 'Neurology', 'Ophthalmology', 'Orthopedic Surgery', 'Pulmonology', 'Radiology', 'Vascular Surgery'];
    const states = ['AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'];

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({ ...prevData, [name]: value }));
        setTouched(prevTouched => ({ ...prevTouched, [name]: true }));
    };

    useEffect(() => {
        if (Object.values(touched).some(field => field)) {
            setError('');
        }
    }, [formData, touched]);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setSuccess('');
        setTouched({});

        const data = new FormData();
        Object.keys(formData).forEach(key => data.append(key, formData[key]));
        data.append('is_vet', true);
        data.append('vet_id', null);
        data.append('owner_id', null);
        data.append('telehealth', 'false');

        try {
            const response = await fetch(`${apiUrl}/api/v1/signup`, {
                method: 'POST',
                body: data,
                headers: {
                    // No need for Authorization header in signup
                }
            });

            const result = await response.json();

            if (response.ok) {
                setSuccess(result.message);
                // Store the token if it's returned from the backend
                if (result.token) {
                    localStorage.setItem('token', result.token);
                }
                setTimeout(() => navigate('/login'), 3000);
            } else {
                setError(result.message || 'An error occurred during signup.');
            }
        } catch (error) {
            setError('An error occurred. Please try again.');
            console.error('Signup error:', error);
        }
    };

    const renderStepContent = (step) => {
        switch (step) {
            case 0:
                return (
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField required fullWidth name="username" label="Username" value={formData.username} onChange={handleChange} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField required fullWidth name="password" label="Password" type="password" value={formData.password} onChange={handleChange} />
                        </Grid>
                    </Grid>
                );
            case 1:
                return (
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField required fullWidth name="first_name" label="First Name" value={formData.first_name} onChange={handleChange} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField required fullWidth name="last_name" label="Last Name" value={formData.last_name} onChange={handleChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField required fullWidth name="email" label="Email" type="email" value={formData.email} onChange={handleChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField fullWidth name="degrees" label="Degrees" value={formData.degrees} onChange={handleChange} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField required fullWidth name="city" label="City" value={formData.city} onChange={handleChange} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <InputLabel>State</InputLabel>
                                <Select name="state" value={formData.state} onChange={handleChange}>
                                    {states.map(state => <MenuItem key={state} value={state}>{state}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField required fullWidth name="zip_code" label="Zip Code" value={formData.zip_code} onChange={handleChange} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <InputLabel>Specialty</InputLabel>
                                <Select name="specialty" value={formData.specialty} onChange={handleChange}>
                                    {specialties.map(specialty => <MenuItem key={specialty} value={specialty}>{specialty}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField required fullWidth name="phone_number" label="Phone Number" value={formData.phone_number} onChange={handleChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField required fullWidth name="practice_name" label="Practice Name" value={formData.practice_name} onChange={handleChange} />
                        </Grid>
                    </Grid>
                );
            default:
                return 'Unknown step';
        }
    };

    return (
        <Container component="main" maxWidth="md">
            <StyledPaper elevation={3}>
                <Typography variant="h4" component="h1" gutterBottom>
                    Veterinarian Signup
                </Typography>
                <Stepper activeStep={activeStep}>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 3, width: '100%' }}>
                    {renderStepContent(activeStep)}
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
                        {activeStep !== 0 && (
                            <Button onClick={handleBack} sx={{ mr: 1 }}>
                                Back
                            </Button>
                        )}
                        <Button
                            variant="contained"
                            onClick={activeStep === steps.length - 1 ? handleSubmit : handleNext}
                        >
                            {activeStep === steps.length - 1 ? 'Sign Up' : 'Next'}
                        </Button>
                    </Box>
                </Box>
                {error && (
                    <Alert severity="error" sx={{ mt: 2 }}>
                        {error}
                    </Alert>
                )}
                {success && (
                    <Alert severity="success" sx={{ mt: 2 }}>
                        {success}
                    </Alert>
                )}
            </StyledPaper>
        </Container>
    );
}

export default BrickAndMortarSignup;