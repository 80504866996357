import React from 'react';
import { Box, Typography, Button, ThemeProvider, createTheme, useTheme } from '@mui/material';
import { styled } from '@mui/system';
import { Link } from 'react-router-dom';

const HomeContainer = styled(Box)({
  height: '100vh',
  width: '100vw',
  backgroundImage: 'url("/ailovet_home_page.png")',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  display: 'flex',
  alignItems: 'center',
});

const HomeContent = styled(Box)(({ theme }) => ({
  maxWidth: '1000px',
  marginLeft: '10%',
  textAlign: 'center',
  color: 'white',
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#B3E5FC', // Light blue color
  color: 'black',
  '&:hover': {
    backgroundColor: '#FFFFFF', // Slightly darker on hover
    color: "#757575"
  },
  borderRadius: '25px',
  padding: '15px 30px',
  fontSize: '1.5rem',
  fontWeight: 500,
  marginTop: '30px',
  textTransform: 'none',
}));

function HomePage() {
  const theme = useTheme();

  return (
    <ThemeProvider theme={theme}>
      <HomeContainer>
        <HomeContent>
          <Typography 
            variant="h1" 
            component="h1" 
            gutterBottom 
            sx={{ 
              textShadow: '4px 4px 8px rgba(0,0,0,0.6)', 
              marginBottom: '20px',
              fontSize: '5rem',
              fontWeight: 700,
              letterSpacing: '2px',
            }}
          >
            AILOVET
          </Typography>
          <Typography 
            variant="h5" 
            gutterBottom 
            sx={{ 
              textShadow: '1px 1px 2px rgba(0,0,0,0.3)', 
              marginBottom: '20px', 
              maxWidth: '800px',
              fontSize: '2.8rem',
              fontWeight: 400,
              letterSpacing: '1px',
              lineHeight: 1.2,
            }}
          >
            Taking care of your pet's health has never been easier
          </Typography>
          <Typography 
            variant="body1" 
            paragraph 
            sx={{ 
              color: 'black', 
              marginBottom: '30px', 
              maxWidth: '800px',
              fontSize: '1.8rem',
              fontWeight: 400,
              lineHeight: 1.4,
            }}
          >
            Faster, easier and more accessible<br />veterinary telehealth consultations
          </Typography>
          <Link to="/login" style={{ textDecoration: 'none' }}>
            <StyledButton sx={{ borderRadius: "25px" }} variant="contained">
              Schedule Virtual Visit
            </StyledButton>
          </Link>
        </HomeContent>
      </HomeContainer>
    </ThemeProvider>
  );
}

export default HomePage;