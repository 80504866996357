import { Link } from "react-router-dom";

function OwnerAppointmentRow({ apt }) {
    const datetime_utc = new Date(apt.datetime_utc + 'Z') // creates a new Date object from the apt.datetime_utc string
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    
    const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        timeZone: userTimeZone
    }
    const localDateTime = datetime_utc.toLocaleString('en-US', options) // converts the Date object to a string in the format of MM/DD/YYYY
    const [date, time] = localDateTime.split(', ')
    
    const veterinarianInfo = apt.veterinarian
        ? `${apt.veterinarian.first_name} ${apt.veterinarian.last_name} ${apt.veterinarian.degrees}`
        : 'Not assigned';

    return (
        <>
            <tr>
                <td>{date}</td>
                <td>{time}</td>
                <td>{apt.pet?.name || 'N/A'}</td>
                <td>{apt.reason}</td>
                <td>{veterinarianInfo}</td>
                <td>{apt.notes}</td>
                <td>
                    {apt.meeting_link !== "In Office Visit" ? (
                        <a href={apt.meeting_link} target="_blank" rel="noopener noreferrer">{apt.meeting_link}</a>
                    ) : (
                        apt.meeting_link
                    )}
                </td>
            </tr>
        </>
    )
}

export default OwnerAppointmentRow;