import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";

export default function Summary({ aptId, notesSaved }) {
  const [aptSummary, setAptSummary] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isGenerating, setIsGenerating] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [initialSummaryGenerated, setInitialSummaryGenerated] = useState(false)
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL || "";

  const fetchSummary = useCallback(async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
      return;
    }

    try {
      const response = await fetch(
        `${apiUrl}/api/v1/check-medical-summary/${aptId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          credentials: "include",
        }
      );

      if (response.ok) {
        const data = await response.json();
        if (data.exists) {
          setAptSummary(data.summary);
        }
        setIsLoading(false);
      } else if (response.status === 401) {
        localStorage.removeItem("token");
        navigate("/login");
      } else {
        throw new Error("Network response was not ok");
      }
    } catch (error) {
      console.error("Error fetching summary:", error);
      setIsLoading(false);
    }
  }, [aptId, navigate, apiUrl]);

  useEffect(() => {
    fetchSummary();
  }, [fetchSummary]);

  const handleGenerateSummary = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
      return;
    }

    setIsGenerating(true);
    try {
      const response = await fetch(`${apiUrl}/get-medical-summary/${aptId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        credentials: "include",
      });

      if (response.ok) {
        const data = await response.json();
        setAptSummary(data);
        setInitialSummaryGenerated(true)
      } else if (response.status === 401) {
        localStorage.removeItem("token");
        navigate("/login");
      } else {
        throw new Error("Network response was not ok");
      }
    } catch (error) {
      console.error("Error generating summary:", error);
    } finally {
      setIsGenerating(false);
    }
  };

  const handleSaveSummary = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
      return;
    }

    try {
      const response = await fetch(
        `${apiUrl}/api/v1/update-medical-summary/${aptId}`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(aptSummary),
          credentials: "include",
        }
      );

      if (response.ok) {
        const data = await response.json();
        console.log(data)
        setAptSummary(data);
        setIsEditing(false);
      } else if (response.status === 401) {
        localStorage.removeItem("token");
        navigate("/login");
      } else {
        throw new Error("Network response was not ok");
      }
    } catch (error) {
      console.error("Error saving summary:", error);
    }
  };

  const handleNavToDifferentials = () => {
    navigate(`/differentials/${aptId}`);
  };

  const formatSummary = (summary) => {
    // Remove ** from category headers
    let formattedSummary = summary?.replace(/\*\*([^*]+)\:\*\*/g, '$1:') // Remove ** from headers
      .replace(/\*\*/g, '') // Remove any remaining **
      .replace(/\*\*([^*]+)\:\*\*\n/g, '$1:\n'); // Ensure category names are followed by a colon and newline
  
    // Add indentation to list items
    formattedSummary = formattedSummary?.replace(/- /g, '&nbsp;&nbsp;&nbsp;&nbsp;- '); // Add indentation before each list item
  
    return formattedSummary;
  };

  const formattedSummary = formatSummary(aptSummary?.summary);

  if (isLoading || isGenerating) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <CircularProgress />
        <Typography sx={{ mt: 2 }}>
          {isGenerating ? "Generating AI Summary" : "Loading..."}
        </Typography>
      </Box>
    );
  }

  return (
    <Box>
      {aptSummary ? (
        <>
            <Button
              variant="contained"
              onClick={handleGenerateSummary}
              sx={{
                borderRadius: "25px",
                backgroundColor: "#0FBEB7",
                mb: 3,
                textTransform: "capitalize",
                "&:hover": {
                  backgroundColor: "#FFFFFF",
                  color: "#757575",
                },
              }}
            >
              Regenerate Summary
            </Button>
          <Typography
            variant="h6"
            component="h2"
            sx={{
              width: "100%",
              justifyContent: "flex-start",
              textAlign: "left",
              mb: 2,
              color: "black",
              display: "inline-block",
              textTransform: "uppercase",
            }}
          >
            <strong>{aptSummary.patient_name}'s Medical Summary</strong>
          </Typography>
          {isEditing ? (
            <>
              <TextField
                label="Summary"
                fullWidth
                multiline
                sx={{ mb: 2 }}
                value={aptSummary.summary}
                onChange={(e) =>
                  setAptSummary({
                    ...aptSummary,
                    summary: e.target.value,
                  })
                }
              />
              <Button
                variant="contained"
                onClick={handleSaveSummary}
                sx={{
                  borderRadius: "25px",
                  backgroundColor: "#0FBEB7",
                  textTransform: "capitalize",
                  "&:hover": {
                    backgroundColor: "#FFFFFF",
                    color: "#757575",
                  },
                }}
              >
                Save Summary
              </Button>
            </>
          ) : (
            <>
              <Typography
                variant="body1"
                sx={{ mb: 2 }}
                component="div"
                dangerouslySetInnerHTML={{
                  __html: formattedSummary?.replace(/\n/g, '<br />'),
                }}
              />
              <Button
                variant="contained"
                sx={{
                  mb: 2,
                  borderRadius: "25px",
                  width: "30%",
                  backgroundColor: "#0FBEB7",
                  textTransform: "capitalize",
                  "&:hover": {
                    backgroundColor: "#FFFFFF",
                    color: "#757575",
                  },
                }}
                onClick={() => setIsEditing(true)}
              >
                Edit Summary
              </Button>
              <Button
                variant="text"
                color="secondary"
                onClick={handleNavToDifferentials}
                sx={{
                  ml: 1,
                  width: "71%",
                  justifyContent: "flex-start",
                  textAlign: "left",
                  textTransform: "capitalize",
                  "&:hover": {
                    color: "#0FBEB7",
                  },
                }}
              >
                Go to Differentials
              </Button>
            </>
          )}
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mt: "150px",
            mb: "150px",
          }}
        >
          {notesSaved ? (
            <Button
              variant="contained"
              onClick={handleGenerateSummary}
              sx={{
                borderRadius: "25px",
                backgroundColor: "#0FBEB7",
                textTransform: "capitalize",
                "&:hover": {
                  backgroundColor: "#FFFFFF",
                  color: "#757575",
                },
              }}
            >
              Generate AI Summary
            </Button>
          ) :
          !notesSaved && (
            <Typography variant="body1" sx={{ mb: 2, color: "#0FBEB7" }}>
              <strong>Summary to go here after recording is submitted</strong>
            </Typography>
          )}
        </Box>
      )}
    </Box>
  );
}
