import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect, useCallback } from "react";
import { TableCell, TableRow, Typography, Select, MenuItem } from "@mui/material";
import { ThemeProvider } from '@mui/material/styles';

function VetAppointmentRow({ apt }) {
    const [status, setStatus] = useState(apt.status);
    const [summaryExists, setSummaryExists] = useState(false);
    const [differentialsExist, setDifferentialsExist] = useState(false);
    const datetime_utc = new Date(apt.datetime_utc + 'Z');
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const navigate = useNavigate();
    const apiUrl = process.env.REACT_APP_API_URL || '';

    const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        timeZone: userTimeZone,
    };

    const localDateTime = datetime_utc.toLocaleString('en-US', options);
    const [date, time] = localDateTime.split(', ');

    const checkMedicalSummary = useCallback(async () => {
        const token = localStorage.getItem('token');
        if (!token) {
            navigate('/login');
            return;
        }

        try {
            const response = await fetch(`${apiUrl}/api/v1/check-medical-summary/${apt.id}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                credentials: 'include'
            });

            if (response.ok) {
                const data = await response.json();
                setSummaryExists(data.exists);
            } else if (response.status === 401) {
                localStorage.removeItem('token');
                navigate('/login');
            } else {
                throw new Error('Network response was not ok');
            }
        } catch (err) {
            console.error(err);
        }
    }, [apt.id, navigate, apiUrl]);

    const checkDifferentialList = useCallback(async () => {
        const token = localStorage.getItem('token');
        if (!token) {
            navigate('/login');
            return;
        }

        try {
            const response = await fetch(`${apiUrl}/api/v1/check-differential-list/${apt.id}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                credentials: 'include'
            });

            if (response.ok) {
                const data = await response.json();
                setDifferentialsExist(data.exists);
            } else if (response.status === 401) {
                localStorage.removeItem('token');
                navigate('/login');
            } else {
                throw new Error('Network response was not ok');
            }
        } catch (err) {
            console.error(err);
        }
    }, [apt.id, navigate, apiUrl]);

    const updateAppointmentStatus = useCallback(async (newStatus) => {
        const token = localStorage.getItem('token');
        if (!token) {
            navigate('/login');
            return;
        }

        try {
            const response = await fetch(`${apiUrl}/api/v1/appointment/${apt.id}`, {
                method: 'PATCH',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ status: newStatus }),
            });

            if (response.ok) {
                setStatus(newStatus);
            } else if (response.status === 401) {
                localStorage.removeItem('token');
                navigate('/login');
            } else {
                throw new Error('Network response was not ok');
            }
        } catch (err) {
            console.error(err);
        }
    }, [apt.id, navigate, apiUrl]);

    useEffect(() => {
        checkMedicalSummary();
        checkDifferentialList();
    }, [checkMedicalSummary, checkDifferentialList]);

    return (
        <TableRow>
            <TableCell>
                <Select 
                    value={status} 
                    onChange={e => updateAppointmentStatus(e.target.value)} 
                    fullWidth
                >
                    <MenuItem value="Scheduled">Scheduled</MenuItem>
                    <MenuItem value="Dictation Complete">Dictation Complete</MenuItem>
                    <MenuItem value="Medical Chart Complete">Medical Chart Complete</MenuItem>
                    <MenuItem value="Recorded in PIMS">Recorded in PIMS</MenuItem>
                </Select>
            </TableCell>
            <TableCell>{date}</TableCell>
            <TableCell>{time}</TableCell>
            <TableCell>{apt.pet.name}</TableCell>
            <TableCell>{apt.reason}</TableCell>
            <TableCell>
                {apt.meeting_link !== "In Office Visit" ? (
                    <a href={apt.meeting_link} target="_blank" rel="noopener noreferrer">{apt.meeting_link}</a>
                ) : (
                    apt.meeting_link
                )}
            </TableCell>
            <TableCell>
                <Link to={`/appointment/${apt.id}`} style={{ textDecoration: 'none' }}>
                    <Typography
                        variant="body2"
                        sx={{
                            color: summaryExists ? '#3991da' : '#0fbeb7', 
                            '&:hover': {
                                textDecoration: 'underline',
                            }
                        }}
                    >
                        {summaryExists ? 'Update Summary' : 'Create Summary'}
                    </Typography>
                </Link>
            </TableCell>
            <TableCell>
                <Link to={`/differentials/${apt.id}`} style={{ textDecoration: 'none' }}>
                    <Typography
                        variant="body2"
                        sx={{
                            color: differentialsExist ? '#3991da' : '#0fbeb7',
                            '&:hover': {
                                textDecoration: 'underline',
                            }
                        }}
                    >
                        {differentialsExist ? 'Update Differentials' : 'Create Differentials'}
                    </Typography>
                </Link>
            </TableCell>
        </TableRow>
    );
}

export default VetAppointmentRow;
