import HomePage from "../components/HomePage";
import { ThemeProvider } from "@mui/material/styles";

function Home({ theme }) {
    return (
        <>
        <ThemeProvider theme={theme}>
            <HomePage />
        </ThemeProvider>
        </>
    );
  }
  
  export default Home;