import { NavLink } from "react-router-dom";


function VetNavBar() {
    return (
        <div className='navbar'>
            <div className='navbar-links'>
                <NavLink className='nav-link' to='/'>Home</NavLink>
                <NavLink className='nav-link' to='/appointments'>Appointments</NavLink>
                <NavLink className='nav-link' to='/account-settings'>Account</NavLink>
                <NavLink className='nav-link' to='/logout'>Logout</NavLink>
            </div>
        </div>
    )
}

export default VetNavBar;