import { NavLink } from "react-router-dom";

function NavBar() {
    return (
        // <h1>Navbar</h1>
        <div className='navbar'>
            <div className='navbar-links'>
                <NavLink className='nav-link' to='/'>Home</NavLink>
                {/* <NavLink className='nav-link' to='/veterinarians'>Veterinarians</NavLink> */}
                {/* <NavLink className='nav-link' to='/appointments'>Appointments</NavLink> */}
                {/* <NavLink className='nav-link' to='/pets'>My Pets</NavLink> */}
                <NavLink className='nav-link' to='/vet-signup'>Veterinarian Signup</NavLink>
                {/* <NavLink className='nav-link' to='/owner-signup'>Owner Signup</NavLink> */}
                <NavLink className='nav-link' to='/login'>Login</NavLink>
                {/* <NavLink className='nav-link' to='/logout'>Logout</NavLink> */}
            </div>
        </div>
    )
}

export default NavBar;