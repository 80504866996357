import VetList from "../components/VetList";

function Veterinarians() {
    return (
        <>
            <div className="banner">
                <img src="sleeping_cat_banner.png" className="banner-image" />
                <h1 className="banner-text">Veterinarians</h1>
            </div>
            <VetList />
        </>

    );
  }
  
  export default Veterinarians;