import { NavLink } from "react-router-dom";

function OwnerNavBar() {

    return (
        <div className='navbar'>
            <div className='navbar-links'>
                <NavLink className='nav-link' to='/'>Home</NavLink>
                <NavLink className='nav-link' to='/veterinarians'>Veterinarians</NavLink>
                <NavLink className='nav-link' to='/appointments'>Appointments</NavLink>
                <NavLink className='nav-link' to='/pets'>My Pets</NavLink>
                <NavLink className='nav-link' to='/logout'>Logout</NavLink>
            </div>
        </div>
    )
}

export default OwnerNavBar;