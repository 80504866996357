import React, { useEffect, useState, useCallback } from "react";
import VetCard from "./VetCard";
import { useNavigate } from "react-router-dom";

function VetList() {
    const [vets, setVets] = useState([]);
    const navigate = useNavigate();
    const apiUrl = process.env.REACT_APP_API_URL || '';

    const fetchVeterinarians = useCallback(async () => {
        const token = localStorage.getItem('token');
        if (!token) {
            navigate('/login');
            return;
        }

        try {
            const response = await fetch(`${apiUrl}/api/v1/veterinarians`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                credentials: 'include'
            });

            if (response.ok) {
                const vetArray = await response.json();
                setVets(vetArray);
            } else if (response.status === 401) {
                localStorage.removeItem('token');
                navigate('/login');
            } else {
                throw new Error('Network response was not ok');
            }
        } catch (err) {
            console.error("Error fetching veterinarians:", err);
        }
    }, [navigate, apiUrl]);

    useEffect(() => {
        fetchVeterinarians();
    }, [fetchVeterinarians]);

    return (
        <>
            <div className="vet-list">
                <div className="cards">
                    {vets.map(vet => {
                        return <VetCard key={vet.id} vet={vet} />
                    })}
                </div>
            </div>
        </>
    );
}

export default VetList;