import VetAppointmentTable from "../components/VetAppointmentTable";
import OwnerAppointmentTable from "../components/OwnerAppointmentTable";
import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

function Appointments({ theme }) {
    const [userType, setUserType] = useState('');
    const navigate = useNavigate();
    const apiUrl = process.env.REACT_APP_API_URL || '';

    useEffect(() => {
        const checkSession = async () => {
            const token = localStorage.getItem('token');
            if (!token) {
                navigate('/login');
                return;
            }

            try {
                const response = await fetch(`${apiUrl}/api/v1/check_session`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                    credentials: 'include'
                });

                if (response.ok) {
                    const data = await response.json();
                    setUserType(data.user_type);
                } else {
                    // If the token is invalid, redirect to login
                    localStorage.removeItem('token');
                    navigate('/login');
                }
            } catch (error) {
                console.error('Session check failed:', error);
                localStorage.removeItem('token');
                navigate('/login');
            }
        };

        checkSession();
    }, [navigate, apiUrl]);
    
    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ position: 'relative', m: 2 }}>
                {userType === 'vet' ? <VetAppointmentTable theme={theme} /> 
                    : userType === 'owner' ? <OwnerAppointmentTable theme={theme} /> 
                : <h1>Loading...</h1>}
            </Box>
        </ThemeProvider>
    );
}
  
export default Appointments;