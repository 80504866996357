import { useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';


function Logout() {
    const [isLoggedOut, setIsLoggedOut] = useState(false);
    const navigate = useNavigate();
    const apiUrl = process.env.REACT_APP_API_URL || '';
    const {handleLogout} = useOutletContext();

    useEffect(() => {
        const token = localStorage.getItem('token');
        fetch(`${apiUrl}/api/v1/logout`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        .then(response => {
            if (response.ok) {
                localStorage.removeItem('token');
                setIsLoggedOut(true);
                handleLogout();
            } else {
                setIsLoggedOut(false);
            }
        })
    }, [])

    return (
        <div>
            <h1>{isLoggedOut ? 'Logged out successfully' : 'Logging out...'}</h1>
        </div>
    );
}

export default Logout;

