import { Link } from "react-router-dom";

function VetCard({ vet }) {
    return (
        <>
            <li className="card">
                <img src={vet.profile}
                    alt="profile-picture"
                />
                <div className="card-content">
                    <h4>{vet.first_name} {vet.last_name} {vet.degrees}</h4>
                    <p>{vet.city}, {vet.state} {vet.zip_code}</p>
                    <p>{vet.specialty}</p>
                    <Link to={`/veterinarian/${vet.id}`}>
                        <button>Schedule Virtual Visit</button>
                    </Link>
                </div>
            </li>
        </>
    )
}

export default VetCard;