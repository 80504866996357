import React, { useState, useEffect, useCallback } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
} from "@mui/material";
import OwnerAppointmentRow from "./OwnerAppointmentRow";
import { useNavigate } from "react-router-dom";

function OwnerAppointmentTable() {
  const [appointments, setAppointments] = useState([]);
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL || '';

  const fetchAppointments = useCallback(async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
      return;
    }

    try {
      const response = await fetch(`${apiUrl}/api/v1/appointments`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        credentials: 'include'
      });

      if (response.ok) {
        const data = await response.json();
        setAppointments(data);
      } else if (response.status === 401) {
        localStorage.removeItem('token');
        navigate('/login');
      } else {
        throw new Error('Network response was not ok');
      }
    } catch (error) {
      console.error("Error fetching appointments:", error);
    }
  }, [navigate, apiUrl]);

  useEffect(() => {
    fetchAppointments();
  }, [fetchAppointments]);

  return (
    <Box sx={{ width: "100%", overflowX: "auto" }}>
      <Typography variant="h4" sx={{ mb: 2 }}>
        My Appointments
      </Typography>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="appointments table">
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Time</TableCell>
              <TableCell>Pet</TableCell>
              <TableCell>Reason</TableCell>
              <TableCell>Veterinarian</TableCell>
              <TableCell>Notes</TableCell>
              <TableCell>Meeting Link</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {appointments.map((apt) => (
              <OwnerAppointmentRow
                key={apt.id}
                apt={apt}
                // onAppointmentUpdated={fetchAppointments}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default OwnerAppointmentTable;