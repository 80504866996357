// TODO: MAKE MORE SECURE BEFORE PRODUCTION

import React, { useState } from 'react';
import { TextField, Button, Typography, Container, Box, Alert } from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { ThemeProvider } from "@mui/material/styles";

const StyledContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: theme.spacing(8),
}));

function ForgotPassword({ theme }) {
  const [username, setUsername] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL || '';

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage('');
    setError('');
    try {
      const response = await fetch(`${apiUrl}/api/v1/reset-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, newPassword }),
      });
      const data = await response.json();
      if (response.ok) {
        setMessage(data.message);
        setTimeout(() => navigate('/login'), 3000); // Redirect to login page after 3 seconds
      } else {
        setError(data.message || 'An error occurred. Please try again.');
      }
    } catch (error) {
      setError('An error occurred. Please try again later.');
    }
  };

  return (
    <ThemeProvider theme={theme}>
    <StyledContainer maxWidth="xs">
      <Typography component="h1" variant="h5">
        Reset Password
      </Typography>
      <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="username"
          label="Username"
          name="username"
          autoComplete="username"
          autoFocus
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          sx={{
            "& .MuiInputLabel-root": {
              color: "initial",
            },
            "& .MuiInputLabel-root.Mui-focused": {
              color: "black", 
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "initial", 
              },
              "&:hover fieldset": {
                borderColor: "initial", 
              },
              "&.Mui-focused fieldset": {
                borderColor: "darkgrey",
                backgroundColor: "#FFFFFF",
              },
            },
          }}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="newPassword"
          label="New Password"
          type="password"
          id="newPassword"
          autoComplete="new-password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          sx={{
            "& .MuiInputLabel-root": {
              color: "initial", 
            },
            "& .MuiInputLabel-root.Mui-focused": {
              color: "black", 
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "initial", 
              },
              "&:hover fieldset": {
                borderColor: "initial", 
              },
              "&.Mui-focused fieldset": {
                borderColor: "darkgrey", 
                backgroundColor: "#FFFFFF", 
              },
            },
          }}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          sx={{
            mt: 2,
            borderRadius: "25px",
            backgroundColor: "#0FBEB7",
            textTransform: "capitalize",
            "&:hover": {
              backgroundColor: "#FFFFFF",
              color: "#757575",
            },
          }}
        >
          Reset Password
        </Button>
      </Box>
      {message && (
        <Alert severity="success" sx={{ mt: 2, width: '100%' }}>
          {message}
        </Alert>
      )}
      {error && (
        <Alert severity="error" sx={{ mt: 2, width: '100%' }}>
          {error}
        </Alert>
      )}
    </StyledContainer>
    </ThemeProvider>
  );
}

export default ForgotPassword;