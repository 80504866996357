import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Typography,
  Card,
  CardContent,
  Button,
  Box,
  Divider,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import ChecklistIcon from "@mui/icons-material/Checklist";
import BrickAndMortarSignup from "./BrickAndMortarSignup";
import TelehealthSignup from "./TelehealthSignup";
import { ThemeProvider } from "@mui/material/styles";

function VetSignup({ theme }) {
  const [signupType, setSignupType] = useState("");
  const navigate = useNavigate();

  if (!signupType) {
    return (
      <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          minHeight: "100vh",
          backgroundColor: "#f5f5f5",
          padding: "2rem",
        }}
      >
        <Typography variant="h3" gutterBottom>
          Veterinarian Signup
        </Typography>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} sm={8} md={6}>
            <Card sx={{ padding: "2rem", textAlign: "center" }}>
              <CardContent>
                <Typography
                  variant="h5"
                  gutterBottom
                  sx={{ fontWeight: 600, marginBottom: 3 }}
                >
                  Brick and Mortar Tools
                </Typography>
                {/* <Typography variant = "h4" color="primary" gutterBottom>
                                    $20/month
                                </Typography> */}
                <Typography
                  variant="h6"
                  color="textSecondary"
                  gutterBottom
                  sx={{ fontWeight: 600, lineHeight: 3 }}
                >
                  Traditional Veterinary Practice
                </Typography>
                <Typography
                  variant="body1"
                  gutterBottom
                  sx={{ lineHeight: 1.5, marginBottom: 4 }}
                >
                  Optimize your in-person veterinary patient management with AI
                  tools.
                </Typography>
                <Divider sx={{ marginY: 2, lineHeight: 2.5 }} />
                <Typography
                  variant="body1"
                  color="textSecondary"
                  gutterBottom
                  sx={{ marginTop: 3 }}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    mb={2}
                  >
                    <CheckIcon sx={{ marginRight: 1 }} />
                    <span>
                      Clinic management software for seamless integration
                    </span>
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    mb={2}
                  >
                    <CheckIcon sx={{ marginRight: 1 }} />
                    <span>
                      AI-powered medical chart generation and analysis
                    </span>
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    mb={2}
                  >
                    <CheckIcon sx={{ marginRight: 1 }} />
                    <span>
                      Advanced AI-assisted differential diagnosis tool
                    </span>
                  </Box>
                </Typography>
                <Button
                  sx={{
                    marginTop: 2,
                    color: "primary",
                    size: "large",
                    borderRadius: "25px",
                    backgroundColor: "#0FBEB7",

                    "&:hover": {
                      backgroundColor: "#FFFFFF",
                      color: "#757575",
                    },
                  }}
                  variant="contained"
                  fullWidth
                  onClick={() => setSignupType("brickAndMortar")}
                >
                  <strong>Sign up for free</strong>
                </Button>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  gutterBottom
                  sx={{ fontWeight: 200, ml: 15, mr: 15, mt: 2 }}
                >
                  * Get started with 5 free appointments when you sign up! Then, easily upgrade to our premium plan to unlock unlimited access.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={8} md={6}>
            <Card sx={{ padding: "2rem", textAlign: "center" }}>
              <CardContent>
                <Typography
                  variant="h5"
                  gutterBottom
                  sx={{ fontWeight: 600, marginBottom: 3 }}
                >
                  Telehealth+
                </Typography>
                {/* <Typography variant = "h4" color="primary" gutterBottom>
                                    $50/month
                                </Typography> */}
                <Typography
                  variant="h6"
                  color="textSecondary"
                  gutterBottom
                  sx={{ fontWeight: 600, lineHeight: 3 }}
                >
                  Virtual Veterinary Services
                </Typography>
                <Typography
                  variant="body1"
                  gutterBottom
                  sx={{ lineHeight: 1.5, marginBottom: 4 }}
                >
                  Expand your practice with our telehealth platform, includes
                  B&M tools.
                </Typography>
                <Divider sx={{ marginY: 2, lineHeight: 2.5 }} />
                <Typography
                  variant="body1"
                  color="textSecondary"
                  gutterBottom
                  sx={{ marginTop: 3 }}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    mb={2}
                  >
                    <CheckIcon sx={{ marginRight: 1 }} />
                    <span>
                      Scheduling and billing software for telehealth services
                    </span>
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    mb={2}
                  >
                    <CheckIcon sx={{ marginRight: 1 }} />
                    <span>
                      Join our telehealth network for increased visibility
                    </span>
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    mb={2}
                  >
                    <CheckIcon sx={{ marginRight: 1 }} />
                    <span>Access to all brick and mortar clinic tools</span>
                  </Box>
                </Typography>
                <Button
                  sx={{
                    marginTop: 2,
                    color: "primary",
                    size: "large",
                  }}
                  variant="contained"
                  fullWidth
                  disabled
                >
                  Coming Soon
                </Button>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
      </ThemeProvider>
    );
  }

  return (
    <>
      {signupType === "brickAndMortar" ? (
        <ThemeProvider theme={theme}>
        <BrickAndMortarSignup />
        </ThemeProvider>
      ) : (
        <ThemeProvider theme={theme}>
        <TelehealthSignup />
        </ThemeProvider>
      )}
    </>
  );
}

export default VetSignup;
