import React, { useState, useEffect } from 'react';
import { Container, Typography, Button, Paper, Grid, Box, CircularProgress, Alert } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ThemeProvider } from "@mui/material/styles";
import { useNavigate } from 'react-router-dom';

const StyledPaper = styled(Paper)(({ theme }) => ({
  marginTop: theme.spacing(8),
  marginBottom: theme.spacing(8),
  padding: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  borderRadius: '15px',
  background: 'linear-gradient(135deg, #ffffff 0%, #f0f4f8 100%)',
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(3),
  padding: '12px 24px',
  fontSize: '1.1rem',
  borderRadius: '25px',
  backgroundColor: '#0FBEB7',
  textTransform: 'capitalize',
  '&:hover': {
    backgroundColor: '#FFFFFF',
    color: '#757575',
  },
}));

function AccountSettings({ theme }) {
  const [user, setUser] = useState(null);
  const [veterinarian, setVeterinarian] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const apiUrl = process.env.REACT_APP_API_URL || '';
  const navigate = useNavigate();

  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    try {
      const response = await fetch(`${apiUrl}/api/v1/check_session`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (response.ok) {
        const userData = await response.json();
        setUser(userData);
        if (userData.vet_id) {
          fetchVeterinarianData(userData.vet_id);
        } else {
          setLoading(false);
        }
      } else {
        console.error('Failed to fetch user data');
        setLoading(false);
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
      setLoading(false);
    }
  };

  const fetchVeterinarianData = async (vetId) => {
    try {
      const response = await fetch(`${apiUrl}/vets/${vetId}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (response.ok) {
        const vetData = await response.json();
        setVeterinarian(vetData);
      } else {
        console.error('Failed to fetch veterinarian data');
      }
    } catch (error) {
      console.error('Error fetching veterinarian data:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleUpgrade = async () => {
    try {
      const response = await fetch(`${apiUrl}/api/v1/upgrade-subscription`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json'
        }
      });

      if (response.ok) {
        const result = await response.json();
        setVeterinarian(prevVet => ({ ...prevVet, subscription_status: result.subscription_status }));
        setSuccess('Subscription upgraded successfully!');
        setError('');
      } else {
        throw new Error('Failed to upgrade subscription');
      }
    } catch (error) {
      console.error('Error upgrading subscription:', error);
      setError('Failed to upgrade subscription. Please try again.');
      setSuccess('');
    }
  };

  const handleCancel = async () => {
    try {
      const response = await fetch(`${apiUrl}/api/v1/cancel-subscription`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json'
        }
      });

      if (response.ok) {
        const result = await response.json();
        setVeterinarian(prevVet => ({ ...prevVet, subscription_status: result.subscription_status }));
        setSuccess('Subscription cancelled successfully!');
        setError('');
      } else {
        throw new Error('Failed to cancel subscription');
      }
    } catch (error) {
      console.error('Error cancelling subscription:', error);
      setError('Failed to cancel subscription. Please try again.');
      setSuccess('');
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (!user || !user.vet_id) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <Typography>You must be logged in as a veterinarian to view this page.</Typography>
      </Box>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ backgroundColor: "#BDE9FB", minHeight: "100vh", padding: 2 }}>
        <Container component="main" maxWidth="md">
          <StyledPaper elevation={3}>
            <Typography
              variant="h4"
              component="h1"
              gutterBottom
              sx={{
                color: "black",
                mb: 3,
                textTransform: "uppercase",
                textShadow: "0px 1px 0px rgba(0, 0, 0, 0.2)"
              }}
            >
              Profile Settings
            </Typography>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} textAlign="center">
                <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                  Subscription Status: {veterinarian?.subscription_status || 'Free'}
                </Typography>
              </Grid>
              {veterinarian?.subscription_status !== 'active' ? (
                <Grid item xs={12} textAlign="center">
                  <StyledButton
                    variant="contained"
                    color="primary"
                    onClick={handleUpgrade}
                  >
                    Upgrade to Premium
                  </StyledButton>
                </Grid>
              ) : (
                <Grid item xs={12} textAlign="center">
                  <StyledButton
                    variant="contained"
                    color="secondary"
                    onClick={handleCancel}
                  >
                    Cancel Subscription
                  </StyledButton>
                </Grid>
              )}
              {error && (
                <Grid item xs={12}>
                  <Alert severity="error" sx={{ mt: 2, width: "100%" }}>
                    {error}
                  </Alert>
                </Grid>
              )}
              {success && (
                <Grid item xs={12}>
                  <Alert severity="success" sx={{ mt: 2, width: "100%" }}>
                    {success}
                  </Alert>
                </Grid>
              )}
            </Grid>
          </StyledPaper>
        </Container>
      </Box>
    </ThemeProvider>
  );
}

export default AccountSettings;