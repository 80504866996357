import React, { useEffect, useState, useCallback } from "react";
// import axios from 'axios'; // Import Axios for API calls
import VetAppointmentRow from "./VetAppointmentRow";
import {
  Button,
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
  TextField,
  Autocomplete,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import NewAppointment from "./NewAppointment";
import { ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

function VetAppointmentTable({ theme }) {
  const [apts, setApts] = useState([]);
  const [filteredApts, setFilteredApts] = useState([]); // Store filtered appointments
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [petOptions, setPetOptions] = useState([]); // For storing pet name options
  const [inputValue, setInputValue] = useState(''); // To handle input value
  const [selectedPetName, setSelectedPetName] = useState(''); // For filtering

  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL || '';

  // Fetch Appointments
  const fetchAppointments = useCallback(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
      return;
    }

    fetch(`${apiUrl}/appointments`, {
      method: "GET",
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      credentials: "include",
    })
      .then((r) => {
        if (r.ok) {
          return r.json();
        } else if (r.status === 401) {
          throw new Error('Unauthorized');
        }
        throw new Error('Network response was not ok');
      })
      .then((aptArray) => {

        setApts(aptArray);
        setFilteredApts(aptArray); // Initialize filtered appointments
      })
      .catch((err) => {
        console.error(err);
        if (err.message === 'Unauthorized') {
          localStorage.removeItem('token');
          navigate('/login');
        }
      });
  }, [navigate, apiUrl]);

  useEffect(() => {
    fetchAppointments();
  }, [fetchAppointments]);

  // Handle Page Change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const paginatedApts = filteredApts.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const petIds = [...new Set(apts.map((apt) => apt.pet_id))];

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    fetchAppointments(); // Fetch appointments after closing the form
  };

  // Fetch pet name options based on user input
  useEffect(() => {

    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
      return;
    }

    if (inputValue.length > 1) {
        fetch(`${apiUrl}/api/v1/pet-names?veterinarian_id=${apts[0].veterinarian_id}&q=${inputValue}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          credentials: "include",
        })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json()
        })
        .then(data => {
          // console.log(data)
          setPetOptions(data)
        });
    }
  }, [inputValue, apiUrl]);

  // Handle filter change
  const handleFilterChange = (event, value) => {
    setSelectedPetName(value);
    if (value) {
      const filtered = apts.filter((apt) => apt.pet.name === value);
      setFilteredApts(filtered);
    } else {
      setFilteredApts(apts); // Reset if no filter is applied
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ backgroundColor: "#BDE9FB", minHeight: "100vh", padding: 2 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 1,
          }}
        >
          <Typography
            variant="h4"
            sx={{
              color: "black",
              mb: 1,
              display: "inline-block",
              textTransform: "uppercase",
            }}
          >
            Appointments
          </Typography>

          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleOpen}
            sx={{
              mb: 2,
              borderRadius: "25px",
              backgroundColor: "#0FBEB7",
              textTransform: "capitalize",
              "&:hover": {
                backgroundColor: "#FFFFFF",
                color: "#757575",
              },
            }}
          >
            Add Appointment
          </Button>
        </Box>

        <NewAppointment
          open={open}
          handleClose={handleClose}
          petIds={petIds}
          onAppointmentCreated={fetchAppointments}
        />

        {apts.length > 0 ? (
          <Paper sx={{ width: "100%", overflow: "hidden", borderRadius: "15px" }}>
            <TableContainer sx={{ maxHeight: 600 }}>
              <Table stickyHeader aria-label="appointments table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontSize: "medium" }}>
                      <strong style={{ textShadow: "0px 1px 0px rgba(0, 0, 0, 0.2)" }}>Status</strong>
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold", fontSize: "medium" }}>
                    <strong style={{ textShadow: "0px 1px 0px rgba(0, 0, 0, 0.2)" }}>Date</strong>
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold", fontSize: "medium" }}>
                    <strong style={{ textShadow: "0px 0px 2px rgba(0, 0, 0, 0)" }}>Time</strong>
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold", fontSize: "medium", width: "200px" }}>
                      {/* Autocomplete for Pet Name filter */}
                      <Autocomplete
                        options={petOptions}
                        getOptionLabel={(option) => option}
                        filterOptions={(x) => x} 
                        onInputChange={(e, value) => setInputValue(value)}
                        onChange={handleFilterChange}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Pet Name"
                            variant="outlined"
                            fullWidth
                          />
                        )}
                      />
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold", fontSize: "medium" }}>
                    <strong style={{ textShadow: "0px 1px 0px rgba(0, 0, 0, 0.2)" }}>Reason for Visit</strong>
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold", fontSize: "medium" }}>
                    <strong style={{ textShadow: "0px 1px 0px rgba(0, 0, 0, 0.2)" }}>Location</strong>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paginatedApts.map((apt) => (
                    <VetAppointmentRow key={apt.id} apt={apt} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[20]}
              component="div"
              count={filteredApts.length} 
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        ) : (
          <p>No appointments to display</p>
        )}
      </Box>
    </ThemeProvider>
  );
}

export default VetAppointmentTable;
