import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Box, Typography, TextField, Button, Paper, Grid, Select, MenuItem, InputLabel, FormControl, Stepper, Step, StepLabel } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledPaper = styled(Paper)(({ theme }) => ({
  marginTop: theme.spacing(8),
  marginBottom: theme.spacing(8),
  padding: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  background: 'linear-gradient(135deg, #ffffff 0%, #f0f4f8 100%)',
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(3),
  padding: '12px 24px',
  fontSize: '1.1rem',
}));

const steps = ['User Info', 'Veterinarian Info', 'Payment'];

function TelehealthSignup() {
    const [activeStep, setActiveStep] = useState(0);
    const [formData, setFormData] = useState({
        username: '', password: '', first_name: '', last_name: '', degrees: '',
        city: '', state: '', zip_code: '', specialty: '', bio: '', calendly_url: '',
        email: '', phone_number: '', vet_license_number: '', practice_region: '',
        practice_name: ''
    });
    const [profile, setProfile] = useState(null);
    const navigate = useNavigate();
    const apiUrl = process.env.REACT_APP_API_URL || '';

    const specialties = ['General Practice', 'Nutrition', 'Internal Medicine', 'Dermatology', 'Cardiology', 'Neurology', 'Ophthalmology', 'Orthopedic Surgery', 'Pulmonology', 'Radiology', 'Vascular Surgery'];
    const states = ['AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'];

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const data = new FormData();
        Object.keys(formData).forEach(key => data.append(key, formData[key]));
        data.append('is_vet', true);
        data.append('vet_id', null);
        data.append('owner_id', null);
        data.append('telehealth', 'true');
        if (profile) data.append('profile', profile);

        fetch(`${apiUrl}/api/v1/signup`, {
            method: 'POST',
            body: data
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            console.log(data);
            if (data.token) {
                localStorage.setItem('token', data.token);
            }
            navigate('/login');
        })
        .catch(error => console.error('Error:', error));
    };

    const renderStepContent = (step) => {
        switch (step) {
            case 0:
                return (
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField required fullWidth name="username" label="Username" value={formData.username} onChange={handleChange} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField required fullWidth name="password" label="Password" type="password" value={formData.password} onChange={handleChange} />
                        </Grid>
                    </Grid>
                );
            case 1:
                return (
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField required fullWidth name="first_name" label="First Name" value={formData.first_name} onChange={handleChange} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField required fullWidth name="last_name" label="Last Name" value={formData.last_name} onChange={handleChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField required fullWidth name="email" label="Email" type="email" value={formData.email} onChange={handleChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField fullWidth name="degrees" label="Degrees" value={formData.degrees} onChange={handleChange} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField required fullWidth name="city" label="City" value={formData.city} onChange={handleChange} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <InputLabel>State</InputLabel>
                                <Select name="state" value={formData.state} onChange={handleChange}>
                                    {states.map(state => <MenuItem key={state} value={state}>{state}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField required fullWidth name="zip_code" label="Zip Code" value={formData.zip_code} onChange={handleChange} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <InputLabel>Specialty</InputLabel>
                                <Select name="specialty" value={formData.specialty} onChange={handleChange}>
                                    {specialties.map(specialty => <MenuItem key={specialty} value={specialty}>{specialty}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField required fullWidth name="phone_number" label="Phone Number" value={formData.phone_number} onChange={handleChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField required fullWidth name="vet_license_number" label="Vet License Number" value={formData.vet_license_number} onChange={handleChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth required>
                                <InputLabel>Practice Region</InputLabel>
                                <Select
                                    name="practice_region"
                                    value={formData.practice_region}
                                    onChange={handleChange}
                                    label="Practice Region"
                                >
                                    <MenuItem value="local">Local</MenuItem>
                                    <MenuItem value="state">State</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField required fullWidth name="practice_name" label="Practice Name" value={formData.practice_name} onChange={handleChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField fullWidth name="calendly_url" label="Calendly URL" value={formData.calendly_url} onChange={handleChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField fullWidth multiline rows={4} name="bio" label="Bio" value={formData.bio} onChange={handleChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <input
                                accept="image/*"
                                style={{ display: 'none' }}
                                id="raised-button-file"
                                type="file"
                                onChange={(e) => setProfile(e.target.files[0])}
                            />
                            <label htmlFor="raised-button-file">
                                <Button variant="outlined" component="span">
                                    Upload Profile Picture
                                </Button>
                            </label>
                        </Grid>
                    </Grid>
                );
            case 2:
                return (
                    <Typography>Beta testing. No payment required.</Typography>
                );
            default:
                return 'Unknown step';
        }
    };

    return (
        <Container component="main" maxWidth="md">
            <StyledPaper elevation={3}>
                <Typography variant="h4" component="h1" gutterBottom>
                    Telehealth Vet Signup
                </Typography>
                <Stepper activeStep={activeStep}>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 3, width: '100%' }}>
                    {renderStepContent(activeStep)}
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
                        {activeStep !== 0 && (
                            <Button onClick={handleBack} sx={{ mr: 1 }}>
                                Back
                            </Button>
                        )}
                        <Button
                            variant="contained"
                            onClick={activeStep === steps.length - 1 ? handleSubmit : handleNext}
                        >
                            {activeStep === steps.length - 1 ? 'Sign Up' : 'Next'}
                        </Button>
                    </Box>
                </Box>
            </StyledPaper>
        </Container>
    );
}

export default TelehealthSignup;

