import { useState, useEffect, useCallback } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

function NewAppointment({ open, handleClose, petIds, onAppointmentCreated }) {
  const [pets, setPets] = useState([]);
  const [newAppointment, setNewAppointment] = useState({
    pet_id: "",
    date: "",
    time: "",
    reason: "",
    meeting_link: "In Office Visit",
    primary_vet: "",
    veterinarian_id: "",
  });
  const [isAddingNewPet, setIsAddingNewPet] = useState(false);
  const [newPet, setNewPet] = useState({
    name: "",
    age: "",
    breed: "",
    sex: "",
    weight: "",
  });
  const [vetSubStatus, setVetSubStatus] = useState(null);
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL || "";

  useEffect(() => {
    if (open) {
      fetchPets();
      fetchVeterinarianId();
    }
  }, [open]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
      return;
    }
    const fetchVetSubStatus = async () => {
      try {
        const response = await fetch(`${apiUrl}/api/v1/check_access`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          credentials: "include",
        });
        if (response.ok) {
          const userStatus = await response.json();
          console.log(userStatus.user_status);
          setVetSubStatus(userStatus?.user_status);
        }
      } catch (error) {
        console.error("Error fetching vet subscription status:", error);
        setVetSubStatus("Error");
      }
    };

    fetchVetSubStatus();
  }, [open]);

  const fetchPets = useCallback(async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
      return;
    }

    try {
      const response = await fetch(`${apiUrl}/api/v1/pets`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        credentials: "include",
      });

      if (response.ok) {
        const allPets = await response.json();
        // Filter pets based on the petIds prop
        const filteredPets = allPets.filter((pet) => petIds.includes(pet.id));
        setPets(filteredPets);
      } else if (response.status === 401) {
        localStorage.removeItem("token");
        navigate("/login");
      } else {
        throw new Error("Failed to fetch pets");
      }
    } catch (error) {
      console.error("Error fetching pets:", error);
    }
  }, [petIds, navigate, apiUrl]);

  const fetchVeterinarianId = useCallback(async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
      return;
    }

    try {
      const response = await fetch(`${apiUrl}/api/v1/check_session`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        credentials: "include",
      });

      if (response.ok) {
        const data = await response.json();
        setNewAppointment((prev) => ({
          ...prev,
          veterinarian_id: data.vet_id,
        }));
      } else if (response.status === 401) {
        localStorage.removeItem("token");
        navigate("/login");
      } else {
        throw new Error("Failed to fetch veterinarian ID");
      }
    } catch (error) {
      console.error("Error fetching veterinarian ID:", error);
    }
  }, [navigate, apiUrl]);

  function handleChange(e) {
    if (e.target.name === "pet_id" && e.target.value === "new") {
      setIsAddingNewPet(true);
      setNewAppointment({ ...newAppointment, pet_id: "" });
    } else {
      setNewAppointment({
        ...newAppointment,
        [e.target.name]: e.target.value,
      });
    }
  }

  function handleNewPetChange(e) {
    setNewPet({ ...newPet, [e.target.name]: e.target.value });
  }

  const handleNewPetSubmit = useCallback(async () => {
    if (!newPet.name.trim()) {
      alert("Pet name is required");
      return;
    }

    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
      return;
    }

    // Convert age and weight to integers, or null if empty
    const petData = {
      ...newPet,
      age: newPet.age ? parseInt(newPet.age, 10) : null,
      weight: newPet.weight ? parseInt(newPet.weight, 10) : null,
    };

    try {
      const response = await fetch(`${apiUrl}/api/v1/create-pet`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(petData),
        // credentials: 'include'
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || "Failed to create new pet");
      }

      const createdPet = await response.json();
      setPets([...pets, createdPet]);
      setNewAppointment({ ...newAppointment, pet_id: createdPet.id });
      setIsAddingNewPet(false);
      setNewPet({ name: "", age: "", breed: "", sex: "", weight: "" });
    } catch (error) {
      console.error("Error creating new pet:", error);
      alert(`Failed to create new pet. Error: ${error.message}`);
    }
  }, [newPet, pets, newAppointment, navigate, apiUrl]);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      if (isAddingNewPet) {
        alert("Please add the new pet before submitting the appointment");
        return;
      }
      const { pet_id, date, time, veterinarian_id, ...rest } = newAppointment;

      if (!pet_id || !date || !time) {
        alert("Please fill in all required fields: Pet, Date, and Time");
        return;
      }

      const localDateTime = new Date(`${date}T${time}`);
      const utcDateTime = localDateTime.toISOString().replace("Z", "+00:00");

      const appointmentData = {
        pet_id: parseInt(pet_id),
        veterinarian_id: parseInt(veterinarian_id),
        datetime_utc: utcDateTime,
        ...rest,
      };

      const token = localStorage.getItem("token");
      if (!token) {
        navigate("/login");
        return;
      }

      try {
        const response = await fetch(`${apiUrl}/api/v1/create_appointment`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(appointmentData),
          credentials: "include",
        });

        if (response.ok) {
          const data = await response.json();
          console.log("Appointment created:", data);
          handleClose();
          onAppointmentCreated(); // Call this after successful creation
          // Reset the form fields
          setNewAppointment({
            pet_id: "",
            date: "",
            time: "",
            reason: "",
            meeting_link: "In Office Visit",
            primary_vet: "",
            veterinarian_id: "",
          });
        } else if (response.status === 401) {
          localStorage.removeItem("token");
          navigate("/login");
        } else {
          throw new Error("Network response was not ok");
        }
      } catch (error) {
        console.error("Error:", error);
        alert("Failed to create appointment. Please try again.");
      }
    },
    [
      isAddingNewPet,
      newAppointment,
      handleClose,
      onAppointmentCreated,
      navigate,
      apiUrl,
    ]
  );

  const handleNavToAccountPage = () => {
    navigate("/account-settings");
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      {vetSubStatus === "ok to proceed" ? (
        <>
          <DialogTitle>New Appointment</DialogTitle>
          <DialogContent>
            <Box component="form" onSubmit={handleSubmit}>
              <TextField
                select
                label="Pet"
                name="pet_id"
                value={newAppointment.pet_id}
                onChange={handleChange}
                fullWidth
                margin="normal"
                required
                disabled={isAddingNewPet}
              >
                {pets.map((pet) => (
                  <MenuItem key={pet.id} value={pet.id}>
                    {pet.name}
                  </MenuItem>
                ))}
                <MenuItem value="new">
                  <b>+ Add New Pet</b>
                </MenuItem>
              </TextField>
              {isAddingNewPet && (
                <Box
                  sx={{
                    mt: 2,
                    mb: 2,
                    p: 2,
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                  }}
                >
                  <Typography variant="h6" gutterBottom>
                    Add New Pet
                  </Typography>
                  <TextField
                    label="Pet Name"
                    name="name"
                    value={newPet.name}
                    onChange={handleNewPetChange}
                    fullWidth
                    margin="normal"
                    required
                  />
                  <TextField
                    label="Age"
                    name="age"
                    value={newPet.age}
                    onChange={handleNewPetChange}
                    fullWidth
                    margin="normal"
                  />
                  <TextField
                    label="Breed"
                    name="breed"
                    value={newPet.breed}
                    onChange={handleNewPetChange}
                    fullWidth
                    margin="normal"
                  />
                  <TextField
                    label="Sex"
                    name="sex"
                    value={newPet.sex}
                    onChange={handleNewPetChange}
                    fullWidth
                    margin="normal"
                  />
                  <TextField
                    label="Weight"
                    name="weight"
                    value={newPet.weight}
                    onChange={handleNewPetChange}
                    fullWidth
                    margin="normal"
                  />
                  <Button
                    onClick={handleNewPetSubmit}
                    variant="contained"
                    color="primary"
                    sx={{
                      mt: 2,
                      borderRadius: "25px",
                      backgroundColor: "primary",
                      textTransform: "capitalize",
                      "&:hover": {
                        backgroundColor: "#FFFFFF",
                        color: "#757575",
                      },
                    }}
                  >
                    Add Pet
                  </Button>
                </Box>
              )}
              <TextField
                label="Date"
                name="date"
                type="date"
                value={newAppointment.date}
                onChange={handleChange}
                fullWidth
                margin="normal"
                required
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                label="Time"
                name="time"
                type="time"
                value={newAppointment.time}
                onChange={handleChange}
                fullWidth
                margin="normal"
                required
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                label="Reason for Visit"
                name="reason"
                value={newAppointment.reason}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Primary Vet"
                name="primary_vet"
                value={newAppointment.primary_vet}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button color="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              color="secondary"
              onClick={handleSubmit}
              disabled={isAddingNewPet}
            >
              Submit
            </Button>
          </DialogActions>
        </>
      ) : (
        <Box
          sx={{
            borderRadius: "10px",
            mx: 2,
            padding: 7,
            maxHeight: "100%",
            maxWidth: "100%",
            boxSizing: "border-box",
            overflow: "auto",
            textAlign: "center",
          }}
          mx={4}
        >
          <Typography
            variant="h6"
            gutterBottom
            sx={{
              color: "black",
              display: "inline-block",
            }}
          >
            <strong>Access Denied: Appointment Limit Reached </strong> <br />
          </Typography>
          <Button
            variant="contained"
            sx={{
              borderRadius: "25px",
              marginLeft: "5px",
              backgroundColor: "#0FBEB7",
              textTransform: "capitalize",
              marginTop: 2,
              "&:hover": {
                backgroundColor: "#FFFFFF",
                color: "#757575",
              },
            }}
            onClick={handleNavToAccountPage}
          >
            Upgrade to Premuim
          </Button>
        </Box>
      )}
    </Dialog>
  );
}

export default NewAppointment;
