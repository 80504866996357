import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function OwnerSignup() {
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [petName, setPetName] = useState('');
    const [petBreed, setPetBreed] = useState('');
    const [petSex, setPetSex] = useState('');
    const [petAge, setPetAge] = useState('');
    const [petWeight, setPetWeight] = useState('');
    const apiUrl = process.env.REACT_APP_API_URL || '';

    function handleSubmit(e) {
        e.preventDefault();
        const data = {
            'username': username,
            'password': password,
            'first_name': firstName,
            'last_name': lastName,
            'email': email,
            'pet_name': petName,
            'pet_breed': petBreed,
            'pet_sex': petSex,
            'pet_age': parseInt(petAge),
            'pet_weight': parseInt(petWeight),
            'is_owner': true,
            'vet_id': null, 
            'owner_id': null // new owner so no existing id
        }
        fetch(`${apiUrl}/api/v1/signup`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            console.log(data);
            if (data.token) {
                localStorage.setItem('token', data.token);
            }
            navigate('/login');
        })
        .catch(error => console.error('Error:', error));
    }
    
    return (
        <>
            <h1 className="title">Pet Owner Signup</h1>
            <div className="container">
                <form className="form" onSubmit={handleSubmit}>
                    <input className="input" type="text" placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)} />
                    <input className="input" type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                    <input className="input" type="text" placeholder="First Name" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                    <input className="input" type="text" placeholder="Last Name" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                    <input className="input" type="text" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                    <input className="input" type="text" placeholder="Pet Name" value={petName} onChange={(e) => setPetName(e.target.value)} />
                    <input className="input" type="text" placeholder="Pet Breed" value={petBreed} onChange={(e) => setPetBreed(e.target.value)} />
                    <select className="input" value={petSex} onChange={(e) => setPetSex(e.target.value)}>
                        <option value="">Select Pet Sex</option>
                        <option value="Male">Male</option>
                        <option value="Male Neutered">Male Neutered</option>
                        <option value="Female">Female</option>
                        <option value="Female Spayed">Female Spayed</option>
                    </select>
                    <input className="input" type="text" placeholder="Pet Age" value={petAge} onChange={(e) => setPetAge(e.target.value)} />
                    <input className="input" type="text" placeholder="Pet Weight (kg)" value={petWeight} onChange={(e) => setPetWeight(e.target.value)} />
                    <button className="button" type="submit">Sign Up</button>
                </form>
            </div>
        </>
    );
  }
  
  export default OwnerSignup;

